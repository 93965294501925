<template>
    <div class="main-s2t">
        <div class="lang-choose" v-if="!lang_speak">
            <div class="lang-box">
                <p>בחר את השפה שאתה מדבר בה</p>
            </div>
            <div class="lang-box" @click="choose_lnag_to_speak('HE')">
                <img src="https://cdn-icons-png.flaticon.com/512/330/330530.png" alt="">
                <p>עברית</p>
            </div>
            <div class="lang-box" @click="choose_lnag_to_speak('RU')">
                <img src="https://cdn-icons-png.flaticon.com/512/330/330437.png" alt="">
                <p>русский</p>
            </div>
        </div>
        <div class="transcription-screen" v-if="lang_speak">
            <div class="conf-icon" v-if="text_transcription.conf">
                {{(text_transcription.conf * 100).toFixed(0)}}%
            </div>
            <p>{{text_transcription.text}}</p>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { projectFunctions } from '../../firebase/config';

export default {
setup(){

    const lang_speak = ref(null);
    const lang_trans = ref('ru');
    const text_transcription = ref({
        text: null,
        conf: 0
    });

    const init_speech_rec = async () => {
        window.SpeechRecognition = window.speechRecognition || window.webkitSpeechRecognition;
        const recognition = new window.SpeechRecognition();
        recognition.interimResults = true;

        recognition.addEventListener('result', (e) => {
            const text = Array.from(e.results).map(result => result[0]).map(result => result.transcript).join('');
            text_transcription.value.text = text
            text_transcription.value.conf = e.results[0][0].confidence
        })

        recognition.addEventListener('end', () => {
            //recognition.start();
            translate_text(text_transcription.value.text)
        })

        recognition.start();
    }

    const choose_lnag_to_speak = async (lang) =>{
        lang_speak.value = lang
        await init_speech_rec();
    }

    const translate_text = async (text) => {
        let res = await projectFunctions.httpsCallable("translate_string")({text:text})
        console.log(res);
    }
    
    return{
        lang_speak, lang_trans, text_transcription, choose_lnag_to_speak
    }
}
}
</script>

<style scoped>
.main-s2t{
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 5px;
}
.lang-choose{
    width: 100%;
    height: 90%;
    background: var(--secondary);
    display: grid;
    grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
    grid-template-rows: calc(50% - 5px);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.377);
    gap: 5px;
}
.lang-box{
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    font-size: 36px;
    width: 100%;
    height: 100%;
    padding: 5px;
    text-align: center;
    flex-direction: column;
}
.lang-box img{
    max-width: 80%;
    max-height: 60%;
}
.transcription-screen{
    position: relative;
    width: 100%;
    height: 90%;
    background: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: white;
}
.conf-icon{
    position: absolute;
    top: 5px;
    left: 5px;
    background: var(--success);
    border-radius: 50%;
    font-size: 30px;
    color: white;
    text-shadow: 0 0 5px black;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>